import React from 'react'
import tw, { styled } from 'twin.macro'
import BottomBG from '../../images/partner-bottom-bg.svg'
import LocalizedLink from '../../components/localized-link'
import { LargeSolidTurquoiseButton } from '../../utils/styles'

const BottomWrapper = styled.div`
  background-image: url(${BottomBG});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 98px 25px;
`

const Contact = ({ contact }) => {
  return (
    <div tw="container mb-28 mt-16">
      <BottomWrapper tw="rounded-lg relative z-10">
        <div tw="grid md:grid-cols-2 gap-y-10 rounded-lg">
          <div tw="text-white max-w-md flex justify-self-end rounded-t-lg md:rounded-l-lg">
            <div tw="text-center md:text-left">
              <h3>{contact.headline.text}</h3>
              <p tw="text-lg mt-4">{contact.contact_description}</p>
            </div>
          </div>
          <div tw="block md:flex items-center justify-center rounded-b-lg md:rounded-r-lg">
            <LocalizedLink to="/request-meeting">
              <LargeSolidTurquoiseButton tw="md:w-72">
                {contact.button}
              </LargeSolidTurquoiseButton>
            </LocalizedLink>
          </div>
        </div>
      </BottomWrapper>
    </div>
  )
}
export default Contact
