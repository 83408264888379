import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'

const OurBenefitsWrapper = styled.div``

const OurBenefits = ({ benefits, items }) => {
  return (
    <OurBenefitsWrapper tw="container pt-20 pb-8 text-center md:pt-28 md:pb-14">
      <h2 tw="text-navy">{benefits.headline.text}</h2>
      <p tw="text-xl text-ocean max-w-xl pt-5 m-auto">
        {benefits.subheadline.text}
      </p>
      <div tw="grid md:grid-cols-2 mt-10 md:mt-20 md:text-left gap-x-10 gap-y-24">
        {items.map((item, key) => {
          return (
            <div key={key}>
              <div tw="flex flex-col md:flex-row gap-5">
                <div tw="w-9  m-auto md:m-0">
                  <Img fluid={item.why_icon.fluid} />
                </div>
                <div>
                  <div>
                    <h5 tw="text-navy">{item.why_headline}</h5>
                  </div>
                  <div tw="text-ocean mt-4 text-lg">{item.why_description}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </OurBenefitsWrapper>
  )
}
export default OurBenefits
