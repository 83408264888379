import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'
import { LocaleContext } from '../components/layout'
import background from '../images/Hero_Background_Image_01.svg'
import OurBenefits from '../components/partner/our-benefits'
import WatchElementAnimate from '../components/watch-element-animate'
import Contact from '../components/partner/contact'
import SolutionTestimonial from '../components/solutions/solution-testimonial'
import Help from '../components/services/help'
import SEO from '../components/SEO/SEO'
import LocalizedLink from '../components/localized-link'
import { withPreview } from 'gatsby-source-prismic'
import {
  LargeSolidTurquoiseButton,
  LargeOutlineTealButton,
} from '../utils/styles'

// styles
const HeroWrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  padding: 138px 0;
  @media (max-width: 640px) {
    padding: 138px 0 50px 0;
  }
`
// markup
const PartnerPage = ({ data: { prismicPartner }, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <main>
      <SEO
        title={prismicPartner.data.meta_title}
        desc={prismicPartner.data.meta_description}
        locale={locale}
      />
      <HeroWrapper
        style={{
          backgroundImage: `url(
            ${prismicPartner.data.hero_background_image.fluid.src}
          )`,
        }}>
        <div tw="container">
          <div tw="grid md:grid-cols-2 md:gap-28">
            <div tw="whitespace-normal">
              <h1 tw="text-navy">{prismicPartner.data.hero_headline.text}</h1>
              <p tw="text-xl text-ocean py-8">
                {prismicPartner.data.hero_subheadline.text}
              </p>
              <LocalizedLink to="/request-meeting" tw="hidden md:block">
                <LargeSolidTurquoiseButton tw="max-w-sm">
                  {i18n.buttons.meeting}
                </LargeSolidTurquoiseButton>
              </LocalizedLink>
            </div>
            <div>
              <Img
                fluid={prismicPartner.data.hero_image.fluid}
                objectFit="contain"
              />
            </div>
            <div tw="md:hidden mt-14">
              <LocalizedLink to="/request-meeting">
                <LargeSolidTurquoiseButton tw="max-w-sm">
                  Request a meeting
                </LargeSolidTurquoiseButton>
              </LocalizedLink>
            </div>
          </div>
        </div>
      </HeroWrapper>
      {prismicPartner.data.body.map((b, index) => {
        switch (b.slice_type) {
          case 'work_with_us':
            return (
              <WatchElementAnimate
                className={`.partner__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`partner__animated__${index}`}>
                  <OurBenefits benefits={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'client_testimonial':
            return (
              <WatchElementAnimate
                className={`.partner__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div
                  className={`partner__animated__${index}`}
                  tw="z-10 relative">
                  <SolutionTestimonial testimonial={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          case 'what_we_are_looking_for':
            return <Help help={b.primary} items={b.items} title={''} />
          case 'contact':
            return (
              <WatchElementAnimate
                className={`.partner__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`partner__animated__${index}`}>
                  <Contact contact={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          default:
            return null
        }
      })}
    </main>
  )
}

export default withPreview(PartnerPage)

export const pageQuery = graphql`
  query PartnerQuery($locale: String!) {
    prismicPartner(lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        hero_headline {
          text
        }
        hero_subheadline {
          text
        }
        hero_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_background_image {
          fluid {
            src
          }
        }
        body {
          ... on PrismicPartnerBodyWorkWithUs {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              why_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              why_headline
              why_description
            }
          }
          ... on PrismicPartnerBodyClientTestimonial {
            slice_type
            primary {
              text
              role
              name
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              link {
                link_type
                type
                lang
                uid
              }
              button
            }
          }
          ... on PrismicPartnerBodyWhatWeAreLookingFor {
            slice_type
            primary {
              ways_description
              headline {
                text
              }
            }
            items {
              way_description
              way_headline
              way_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicPartnerBodyContact {
            slice_type
            primary {
              button
              contact_description
              headline {
                text
              }
            }
          }
        }
      }
    }
  }
`
